import React from "react";
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    RedditShareButton,
    RedditShareCount,
    RedditIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    PinterestShareCount
  } from "react-share";

import "../styles/sharebar.css";

export default ({ title, url }) => {
    const shareUrl = url;
    return <div>
        <TwitterShareButton
            quote={title}
            url={shareUrl}
          >
            <TwitterIcon size={32} />
        </TwitterShareButton>

        <RedditShareButton
            url={shareUrl}
            quote={title}
          >
            <RedditIcon size={32} />
            <RedditShareCount url={shareUrl}>
              {count => count}
            </RedditShareCount>
        </RedditShareButton>

        <FacebookShareButton
            quote={title}
            url={shareUrl}
          >
            <FacebookIcon size={32} />
            <FacebookShareCount url={shareUrl}>
              {count => count}
            </FacebookShareCount>
        </FacebookShareButton>

        <PinterestShareButton
            quote={title}
            url={shareUrl}
          >
            <PinterestIcon size={32} />
            <PinterestShareCount url={shareUrl}>
              {count => count}
            </PinterestShareCount>
        </PinterestShareButton>

        <LinkedinShareButton
            quote={title}
            url={shareUrl}
          >
            <LinkedinIcon size={32} />
        </LinkedinShareButton>
    </div>
}